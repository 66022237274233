import React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import Container from './container';
import Tags from './tags';
import * as styles from './rule-preview.module.css';

const RulePreview = ({ rules }) => {
  if (!rules) return null;
  if (!Array.isArray(rules)) return null;

  return (
    <Container>
      <ul className={styles.ruleList}>
        {rules.map((rule) => {
          return (
            <li key={rule.slug}>
              <Link to={`/rules/${rule.slug}`} className={styles.link}>
                <GatsbyImage alt="" image={rule.heroImage.gatsbyImageData} />
                <h2 className={styles.title}>{rule.title}</h2>
              </Link>
              {/* <div
                dangerouslySetInnerHTML={{
                  __html: post.description.childMarkdownRemark.html,
                }}
              /> */}
              <div className={styles.meta}>
                <small className="meta">{rule.publishedDate}</small>
                <Tags tags={rule.tags} />
              </div>
            </li>
          );
        })}
      </ul>
    </Container>
  );
};

export default RulePreview;
