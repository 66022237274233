// Libraries
import React from 'react';
import { graphql } from 'gatsby';
import get from 'lodash/get';
// Components
import Seo from '../components/seo';
import Layout from '../components/layout';
import Hero from '../components/hero';
import RulePreview from '../components/rule-preview';

class RulesIndex extends React.Component {
  render() {
    const rules = get(this, 'props.data.allContentfulRules.nodes');

    return (
      <Layout location={this.props.location}>
        <Seo title="Rules" />
        <Hero
          title="Rules"
          content="A compilation of house rules, miniature rules, etc."
        />
        <RulePreview rules={rules} />
      </Layout>
    );
  }
}

export default RulesIndex;

export const pageQuery = graphql`
  query RulesIndexQuery {
    allContentfulRules {
      nodes {
        title
        slug
        publishedDate(formatString: "MMMM Do, YYYY")
        heroImage {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED
            width: 424
            height: 212
          )
        }
      }
    }
  }
`;
